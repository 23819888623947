.pricing-section .pricing .pricing-item {
    background-color: white;
    padding: 40px 20px;
    box-shadow: 0 3px 20px -2px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid color-mix(in srgb, #1a1f24, transparent 90%);
    border-radius: 10px;
    overflow: hidden;
  }
  
  
  .pricing-section .pricing .pricing-header {
    background-color: #0039a6;
    text-align: center;
    padding: 20px;
    margin: -60px -40px 0;
  }
  
  .pricing-section .pricing h3 {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 36px;
    color: white;
  }
  
  .pricing-section .pricing h4 {
    font-size: 48px;
    color: white;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .pricing-section .pricing h4 sup {
    font-size: 28px;
  }
  
  .pricing-section .pricing h4 span {
    color: color-mix(in srgb, white, transparent 20%);
    font-size: 24px;
  }
  
  .pricing-section .pricing ul {
    padding: 10px 0;
    list-style: none;
    color: color-mix(in srgb,#1a1f24, transparent 30%);
    text-align: left;
    line-height: 20px;
  }
  
  .pricing-section .pricing ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1.2; 
  }
  
  .pricing-section .pricing ul i {
    color: #008080;
    font-size: 20px;
    padding-right: 10px;
    line-height: 0;
  }
  

  .pricing-section .pricing ul .na span {
    text-decoration: line-through;
  }
  
  .pricing-section .pricing .buy-btn {
    display: inline-block;
    padding: 12px;
    border-radius: 6px;
    color:#1a1f24;
    transition: none;
    font-size: 16px;
    font-weight: 500;
    transition: 0.3s;
    border: 1px solid#1a1f24;
  }
  
  .pricing-section .pricing .buy-btn:hover {
    background:  #0ea2bd;
    color: white;
    border-color:  #0ea2bd;
  }
  
  
  .pricing-section .pricing .featured .pricing-header {
  background: #0039a6;
  }
  
  .pricing-section .pricing .featured .buy-btn {
    border-color:  black;
  }
  