/* Common css */
.resm_pic{
  height: 150px !important;
  min-width: 150px !important;
  width: auto !important;
}
.white{
  color:#fff !important;
}
.newresumedivbox .fa::before,.newresumedivbox .fas::before{
  margin-right: 2px;
}
.resume p,.resume .data{
  /* word-wrap: break-word; */
  /* word-break:break-all; */
  }
  
  /* 1.html css start  */
  
  .newresumetemplate01 .resume {
    width: 700px;
    /* margin: 20px auto; */
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
   
  }
  .newresumetemplate01 .left-section {
    background-color: #f0f0f0;
    padding: 15px;
    text-align: center;
  }
  .newresumetemplate01 .left-section img {
    width: 200px;
    height: auto;
    margin-bottom: 10px;
  }
  .newresumetemplate01 .right-section {
    padding: 15px;
  }
  .newresumetemplate01 h2 {
    font-size: 18px;
    color: black;
    margin-bottom: 10px;
  }
  .newresumetemplate01 .section-title {
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
  }
  .newresumetemplate01 .basic-info p,
  .skills p {
    margin: 5px 0;
    font-size: 15px;
    text-align: left;
  }
  .newresumetemplate01 .right-section p {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .newresumetemplate01 .right-section .signature {
    /* margin-top: 30px; */
    font-size: 15px;

    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: white;
    box-sizing: border-box;
  
  }
  .newresumetemplate01 .heading-sectionresume h2 {
    background-color: #a3a2a2;
    font-size: 17px;
    padding:5px 5px;
  }
  .newresumetemplate01 .heading-sectionresume p{
    font-size: 15px;
  }
  
  /* template 1 end  */
  
  
  
  /* template 2  */
  
  
  .newresumetemplate02 .resume {
    width: 800px;
    margin: auto 20px;
    padding: 20px 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .newresumetemplate02 .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 2px solid #000;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .newresumetemplate02 .personal-info {
    flex: 2;
  }
  
  .newresumetemplate02 .personal-info h1 {
    margin: 0;
    font-size: 25px;
    font-weight: bold;
  }
  
  .newresumetemplate02 .personal-info p {
    margin: 5px 0;
    font-size: 15px;
  }
  
  .newresumetemplate02 .photo {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .newresumetemplate02 .photo img{
    height: 200px;
    width: auto;
  }
  
  .newresumetemplate02 .photo-placeholder {
    /* width: 100px;
    height: 100px; */
    border: 2px solid #b4b7bb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  
  .newresumetemplate02 .content {
    padding: 10px 0;
  }
  
  .newresumetemplate02 .section {
    margin-bottom: 20px;
  }
  
  .newresumetemplate02 .section h2 {
    margin: 0;
    font-size: 17px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .newresumetemplate02 .section p {
    margin: 5px 0;
    font-size: 15px;
  }
  
  .newresumetemplate02 .footer {
    text-align: right;
    margin-top: 20px;
  }
  
  .newresumetemplate02 .footer p {
    margin: 5px 0;
  }
  .newresumetemplate02 h2{
    font-weight: 600;
    border-bottom: 1px solid black;
  }
  
  
  
  .firsttemplate body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    color: #333;
  }
  .row-print2{
    margin: 0px 0px !important;
    padding:0px 0px !important;
  }
  .firsttemplate .resume-container {
    background-color: #fff;
    padding: 0;
    margin-top: 20px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.1); */
    overflow: hidden;
  }
  
  .firsttemplate .left-section {
    background: #2c3e50;
    color: #fff;
    padding: 20px;
    min-height: 90vh;
  }
  
  .firsttemplate .left-section h2, .left-section h4, .left-section h5 {
    color: #ecf0f1;
  }
  
  .firsttemplate .left-section p, .left-section li {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .firsttemplate .right-section {
    background-color: #ecf0f1;
    padding: 20px;
  }
  
  .firsttemplate .right-section h4, .right-section h5, .right-section h6 {
    color: #2c3e50;
  }
  
  .firsttemplate .right-section p {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .firsttemplate ul {
    list-style: none;
    padding: 0;
  }
  
  .firsttemplate img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .firsttemplate hr {
    border: 1px solid #ddd;
  }
  
  .firsttemplate .text-center {
    text-align: center;
  }
  /* 1.html css end  */
  
  
  /* =================================================== 2.html start  */
  
  .secondtemplate * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .secondtemplate html {
    height: 100%;  
  }
  
  .secondtemplate  body {
    min-height: 100%;  
    background: #eee;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: #222;
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 50px;
  }
  
  .secondtemplate .container {
    max-width: 700px;   
    background: #fff;
    margin: 0px auto 0px; 
    box-shadow: 1px 1px 2px #DAD7D7;
    border-radius: 3px;  
    padding: 40px;
    /* margin-top: 50px; */
  }
  
  .secondtemplate .header {
    margin-bottom: 30px;
    
    .full-name {
      font-size: 40px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    
    .first-name {
      font-weight: 700;
    }
    
    .last-name {
      font-weight: 300;
    }
    
    .contact-info {
      margin-bottom: 20px;
    }
    
    .email ,
    .phone {
      color: #999;
      font-weight: 300;
    } 
    
    .separator {
      height: 10px;
      display: inline-block;
      border-left: 2px solid #999;
      margin: 0px 10px;
    }
    
    .position {
      font-weight: bold;
      display: inline-block;
      margin-right: 10px;
      text-decoration: underline;
    }
  }
  
  
  .secondtemplate .details {
    line-height: 20px;
    
    .section {
      margin-bottom: 40px;  
    }
    
    .section:last-of-type {
      margin-bottom: 0px;  
    }
    
    .section__title {
      letter-spacing: 2px;
      color: #54AFE4;
      font-weight: bold;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    
    .section__list-item {
      margin-bottom: 40px;
    }
    
    .section__list-item:last-of-type {
      margin-bottom: 0;
    }
    
    .left ,
    .right {
      vertical-align: top;
      display: inline-block;
    }
    
    .left {
      width: 60%;    
    }
    
    .right {
      text-align: right;
      width: 39%;    
    }
    
    .name {
      font-weight: bold;
    }
    
    a {
      text-decoration: none;
      color: #000;
      font-style: italic;
    }
    
    a:hover {
      text-decoration: underline;
      color: #000;
    }
    
   
    .skills__item {
      margin-bottom: 10px;  
    }
    
    .skills__item .right {
      input {
        display: none;
      }
      
      label {
        display: inline-block;  
        width: 20px;
        height: 20px;
        background: #C3DEF3;
        border-radius: 20px;
        margin-right: 3px;
      }
      
      input:checked + label {
        background: #79A9CE;
      }
    }
  }
  
  /* =================================================== 2.html end  */
  
  /* =================================================== 3.html css start */
  
  /* @page {
  size : A4;
  margin : 0;
  } */
  
  .thirdtemplate *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  
  }
  
  .thirdtemplate body{
  background-color: #969292;
  }
  
  .thirdtemplate .main-content{
  min-height: 100vh;
  width: 80%;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  }
  
  .thirdtemplate .left-section{
  grid-column: span 2;
  height: 100%;
  background-color:#397be6;
  }
  .thirdtemplate .right-section{
  grid-column: span 5;
  background-color: #f7f7f7;
  height: 100%;
  }
  
  
  .thirdtemplate .left-content{
  padding: 2rem 3rem;
  }
  .thirdtemplate .profile{
  width: 100%;
  border-bottom: 1px solid #002333;
  }
  
  .thirdtemplate .image{
  width: 100%;
  text-align: center;
  }
  .thirdtemplate .profile img{
  width: 100%;
  border-radius: 50%;
  border: 8px solid #002333;
  
  }
  
  .thirdtemplate .name{
  font-size: 2rem;
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 1.2rem 0;
  }
  
  .thirdtemplate .career{
  font-size: 1.2rem;
  color: #94D9EA;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 1rem;
  }
  
  .thirdtemplate .main-title{
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #f7f7f7ec;
  padding-top: 3rem;
  }
  
  .thirdtemplate .contact-info ul{
  padding-top: 2rem;
  }
  
  .thirdtemplate .contact-info ul li{
  padding: .4rem 0;
  display: flex;
  align-items: center;
  color: #fff;
  }
  .thirdtemplate .contact-info ul li i{
  padding-right: 1rem;
  font-size: 1.2rem;
  color: #2D9CDB;
  }
  
  .thirdtemplate .skills-section ul{
  padding-top: 2rem;
  }
  .thirdtemplate .skills-section ul li{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: .4rem 0;
  }
  
  .thirdtemplate .progress-bar{
  width: 100%;
  height: .4rem;
  background-color: #2f81ed5b;
  position: relative;
  border-radius: 12px;
  }
  .thirdtemplate .progress{
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #2D9CDB;
  border-radius: 12px;
  }
  .thirdtemplate .js-progress{
  width: 70%;
  }
  .thirdtemplate .ps-progress{
  width: 90%;
  }
  .thirdtemplate .j-progress{
  width: 85%;
  }
  .thirdtemplate .c-progress{
  width: 40%;
  }
  .thirdtemplate .n-progress{
  width: 63%;
  }
  .thirdtemplate .w-progress{
  width: 78%;
  }
  
  
  .thirdtemplate .skill-title{
  text-transform: uppercase;
  color: #f7f7f7;
  font-size: 1rem;
  }
  
  .thirdtemplate .sub-title{
  padding-top: 2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #f7f7f7;
  }
  
  .thirdtemplate .sub-para{
  color: #ccc;
  padding: .4rem 0;
  }
  
  .thirdtemplate .references-section li{
  color: #ccc;
  padding: .2rem 0;
  }
  .thirdtemplate .references-section li i{
  padding-right: .5rem;
  font-size: 1.2rem;
  color: #2D9CDB;
  }
  
  .thirdtemplate .right-main-content{
  padding: 2rem 3rem;
  }
  
  
  .thirdtemplate .right-title{
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #2F80ED;
  margin-bottom: 1.2rem;
  position: relative;
  }
  
  .thirdtemplate .right-title::after{
  content: "";
  position: absolute;
  width: 60%;
  height: .2rem;
  background-color: #ccc;
  border-radius: 12px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  }
  
  .thirdtemplate .para{
  line-height: 1.6rem;
  color: #718096;
  font-size: 1.1rem;
  }
  
  .thirdtemplate  .sect{
  padding-bottom: 2rem;
  }
  
  .thirdtemplate .timeline{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  }
  
  .thirdtemplate .tl-title{
  letter-spacing: 1px;
  font-size: 1.3rem;
  color: #002333;
  text-transform: uppercase;
  }
  .thirdtemplate .tl-title-2{
  letter-spacing: 1px;
  font-size: 1.3rem;
  color: #2D9CDB;
  text-transform: uppercase;
  }
  
  .thirdtemplate .tl-content{
  border-left: 1px solid #ccc;
  padding-left: 2rem;
  position: relative;
  padding-bottom: 2rem;
  }
  
  .thirdtemplate .tl-title-2::before{
  content: "";
  position: absolute;
  width: .7rem;
  height: .7rem;
  background-color: #2D9CDB;
  border-radius: 50%;
  transform: translateX(-50%);
  left: 0;
  }
  
  /*Media Querries*/
  @media screen and (max-width:823px){
  .thirdtemplate .right-title::after{
  width: 40%;
  }
  }
  @media screen and (max-width:681px){
  .thirdtemplate  .right-title::after{
  width: 30%;
  }
  }
  @media screen and (max-width:780px){
  .thirdtemplate .timeline{
  grid-template-columns: repeat(1, 1fr);
  }
  }
  @media screen and (max-width:780px){
  .thirdtemplate  .left-section{
  grid-column: span 3;
  }
  .right-section{
  grid-column: span 4;
  }
  }
  @media screen and (max-width:1200px){
  .thirdtemplate .main-content{
  grid-template-columns: repeat(1, 1fr);
  }
  .profile img{
  width: 40%;
  }
  }
  @media screen and (max-width:700px){
  .thirdtemplate .profile img{
  width: 60%;
  }
  }
  @media screen and (max-width:390px){
  .thirdtemplate  .name{
  font-size: 1.5rem;
  }
  }
  
  /*======================================================== 3.html css end */
  
  
  /*======================================================== 4.html css start */
  @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap");
  
  .fourthtemplate * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  /* font-family: "Montserrat", sans-serif; */
  }
  
  .fourthtemplate body {
  background: #585c68;
  font-size: 14px;
  line-height: 22px;
  color: #555555;
  }
  
  .fourthtemplate .bold {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  }
  
  .fourthtemplate .semi-bold {
  font-weight: 500;
  font-size: 16px;
  }
  
  .fourthtemplate .resume {
  width: 800px;
  height: auto;
  display: flex;
  margin: 50px auto;
  }
@media print{
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .fourthtemplate .resume .resume_left{
    width: 280px;
    background: #0bb5f4 !important;
    min-height: 90vh;
  }
  
}
  
  .fourthtemplate .resume .resume_left {
  width: 280px;
  background: #0bb5f4 !important;
  min-height: 90vh;
  }
  
  .fourthtemplate .resume .resume_left .resume_profile {
  width: 100%;
  /* height: 280px; */
  }
  
  .fourthtemplate .resume .resume_left .resume_profile img {
  width: 100%;
  height: auto !important;
  }
  
  .fourthtemplate .resume .resume_left .resume_content {
  padding: 0 25px;
  }
  
  .fourthtemplate .resume .title {
  margin-bottom: 20px;
  }
  
  .fourthtemplate .resume .resume_left .bold {
  color: #fff;
  }
  
  .fourthtemplate .resume .resume_left .regular {
  color: #fff;
  }
  
  .fourthtemplate .resume .resume_item {
  padding: 25px 0;
  border-bottom: 2px solid #b1eaff;
  }
  
  .fourthtemplate .resume .resume_left .resume_item:last-child,
  .resume .resume_right .resume_item:last-child {
  border-bottom: 0px;
  }
  
  .fourthtemplate .resume .resume_left ul li {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  }
  
  .fourthtemplate .resume .resume_left ul li:last-child {
  margin-bottom: 0;
  }
  
  .fourthtemplate .resume .resume_left ul li .icon {
  width: 12px;
  height: 12px;
  background: #fff;
  color: #0bb5f4;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 16px;
  position: relative;
  }
  
  .fourthtemplate .resume .icon i,
  .resume .resume_right .resume_hobby ul li i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
  
  .fourthtemplate .resume .resume_left ul li .data {
  color: #fff;
  }
  
  .fourthtemplate .resume .resume_left .resume_skills ul li {
  display: flex;
  margin-bottom: 10px;
  color: #b1eaff;
  justify-content: space-between;
  align-items: center;
  }
  
  .fourthtemplate .resume .resume_left .resume_skills ul li .skill_name {
  width: 25%;
  }
  
  .fourthtemplate .resume .resume_left .resume_skills ul li .skill_progress {
  width: 60%;
  margin: 0 5px;
  height: 5px;
  background: #009fd9;
  position: relative;
  }
  
  .fourthtemplate .resume .resume_left .resume_skills ul li .skill_per {
  width: 15%;
  }
  
  .fourthtemplate .resume .resume_left .resume_skills ul li .skill_progress span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #fff;
  }
  
  .fourthtemplate .resume .resume_left .resume_social .semi-bold {
  color: #fff;
  margin-bottom: 3px;
  }
  
  .fourthtemplate .resume .resume_right {
  width: 520px;
  background: #fff;
  padding: 25px;
  }
  
  .fourthtemplate .resume .resume_right .bold {
  color: #0bb5f4;
  }
  
  .fourthtemplate .resume .resume_right .resume_work ul,
  .resume .resume_right .resume_education ul {
  padding-left: 40px;
  overflow: hidden;
  }
  
  .fourthtemplate .resume .resume_right ul li {
  position: relative;
  }
  
  .fourthtemplate .resume .resume_right ul li .date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  }
  
  .fourthtemplate .resume .resume_right ul li .info {
  margin-bottom: 20px;
  }
  
  .fourthtemplate .resume .resume_right ul li:last-child .info {
  margin-bottom: 0;
  }
  
  .fourthtemplate .resume .resume_right .resume_work ul li:before,
  .resume .resume_right .resume_education ul li:before {
  content: "";
  position: absolute;
  top: 5px;
  left: -25px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 2px solid #0bb5f4;
  }
  
  .fourthtemplate .resume .resume_right .resume_work ul li:after,
  .resume .resume_right .resume_education ul li:after {
  content: "";
  position: absolute;
  top: 14px;
  left: -21px;
  width: 2px;
  height: 115px;
  background: #0bb5f4;
  }
  
  .fourthtemplate .resume .resume_right .resume_hobby ul {
  display: flex;
  justify-content: space-between;
  }
  
  .fourthtemplate .resume .resume_right .resume_hobby ul li {
  width: 80px;
  height: 80px;
  border: 2px solid #0bb5f4;
  border-radius: 50%;
  position: relative;
  color: #0bb5f4;
  }
  
  .fourthtemplate .resume .resume_right .resume_hobby ul li i {
  font-size: 30px;
  }
  
  .fourthtemplate .resume .resume_right .resume_hobby ul li:before {
  content: "";
  position: absolute;
  top: 40px;
  right: -52px;
  width: 50px;
  height: 2px;
  background: #0bb5f4;
  }
  
  .fourthtemplate .resume .resume_right .resume_hobby ul li:last-child:before {
  display: none;
  }
  
  /*======================================================== 4.html css end */
  
  /*======================================================== 5.html css start */
    
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
  .fifthtemplate * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: "Poppins", SansSerif; */
  }
  
  .fifthtemplate body {
    background-color: lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  .fifthtemplate .container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    min-height: 1000px;
    background-color: #fff;
    margin: 50px;
    /* display: grid; */
    display: flex;
    grid-template-columns: 1fr 2fr;
    box-shadow: 0 35px 55px rgba(0, 0, 0, 0.1);
  }
  .fifthtemplate .container .left_Side {
    position: relative;
    background-color: #280047;
    padding: 40px;
    width:36%;
    min-height: 90vh;
  }
  .fifthtemplate .profileText {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .fifthtemplate .profileText .imgBx {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
  }
  .fifthtemplate .profileText .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .fifthtemplate .photo {
    width: 100%;
    height: auto;
  }
  .fifthtemplate .profileText h2 {
    color: #fff;
    font-size: 1.5em;
    margin-top: 20px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    line-height: 1.4em;
  }
  .fifthtemplate .profileText h2 span {
    font-size: 0.8em;
    font-weight: 300;
  }
  .fifthtemplate .contactInfo {
    padding-top: 40px;
  }
  .fifthtemplate .title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  .fifthtemplate .contactInfo ul {
    position: relative;
  }
  .fifthtemplate .contactInfo ul li {
    list-style: none;
    position: relative;
    margin: 10px 0;
    cursor: pointer;
  }
  .fifthtemplate .contactInfo ul li .icon {
    display: inline-block;
    width: 30px;
    font-size: 18px;
    color: #03a9f4;
  }
  .fifthtemplate .contactInfo ul li span {
    color: #fff;
    font-weight: 300;
  }
  .fifthtemplate .contactInfo.education li {
    margin-bottom: 15px;
  }
  .fifthtemplate .contactInfo.education h5 {
    color: #03a9f4;
    font-weight: 500;
  }
  .fifthtemplate .contactInfo.education h4:nth-child(2) {
    color: #fff;
    font-weight: 500;
  }
  .fifthtemplate .contactInfo.education h4 {
    color: #fff;
    font-weight: 300;
  }
  .fifthtemplate .contactInfo.language .percent {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: #081921;
    display: block;
    margin-top: 5px;
  }
  .fifthtemplate .contactInfo.language .percent div {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #03a9f4;
  }
  .fifthtemplate .container .right_Side {
    position: relative;
    background-color: #fff;
    padding: 40px;
    width: 70%;
  }
  .fifthtemplate .container h2{
    font-size: calc(1rem + 0.6vw);
  }
  .fifthtemplate .about {
    margin-bottom: 20px;
  }
  .fifthtemplate .about:last-child {
    margin-bottom: 0;
  }
  .fifthtemplate .title2 {
    color: #003147;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  .fifthtemplate p {
    color: #081921;
  }
  .fifthtemplate .about .box {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
  }
  .fifthtemplate .about .box .year_company {
    min-width: 200px;
    padding-right: 10px;
  }
  .fifthtemplate .about .box .year_company h5 {
    text-transform: uppercase;
    color: #848c90;
    font-weight: 600;
  }
  .fifthtemplate .about .box .text h4 {
    text-transform: uppercase;
    color: #2a7da2;
    font-size: 16px;
  }
  .fifthtemplate .skills .box {
    position: relative;
    width: 100%;
    /* display: grid; */
    grid-template-columns: 150px 1fr;
    justify-content: center;
    align-items: center;
  }
  .fifthtemplate .skills .box h4 {
    text-transform: uppercase;
    color: #848c90;
    font-weight: 500;
  }
  .fifthtemplate .skills .box .percent {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #f0f0f0;
  }
  .fifthtemplate .skills .box .percent div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #03a9f4;
  }
  .fifthtemplate .interest ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .fifthtemplate .interest ul li {
    list-style: none;
    color: black;
    font-weight: 500;
    margin: 10px 0;
  }
  .fifthtemplate .interest ul li .fa {
    color: #03a9f4;
    font-size: 18px;
    /* width: 20px; */
  }
  @media (max-width: 1000px) {
    .fifthtemplate .container {
      margin: 10px;
      grid-template-columns: repeat(1, 1fr);
    }
    .fifthtemplate .interest ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width: 600px) {
    .fifthtemplate .about .box {
      flex-direction: column;
    }
    .fifthtemplate .about .box .year_company {
      margin-bottom: 5px;
    }
    .fifthtemplate .interest ul {
      grid-template-columns: repeat(1, 1fr);
    }
    .fifthtemplate .skills .box {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .fifthtemplate .englishw50 {
    width: 60%;
  }
  .fifthtemplate .russianw90 {
    width: 90%;
  }
  .fifthtemplate .frenchw30 {
    width: 30%;
  }
  .fifthtemplate .htmlws30 {
    width: 50%;
  }
  .fifthtemplate .cssws45 {
    width: 45%;
  }
  .fifthtemplate .jsws70 {
    width: 70%;
  }
  .fifthtemplate .phws40 {
    width: 40%;
  }
  .fifthtemplate .ilws60 {
    width: 60%;
  }
  .fifthtemplate .adw70 {
    width: 70%;
  }
  
  /*======================================================== 5.html css end */
  
  /*======================================================== 6.html css start */
  .sixthtemplate html {
    box-sizing: border-box;
  }
  .sixthtemplate *, *:after, *:before {
    box-sizing: inherit;
  }
  .sixthtemplate .container {
    display: flex;
    max-width: 960px;
    background-color: #eaeaea;
    justify-content: space-between;
    margin: 20px auto;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.1)
  }
  .sixthtemplate .profile {
    flex-basis: 35%;
    background-color: #39383a;
    color: #ababab;
  }
  .sixthtemplate .profile-photo img {
    height: 270px;
    width: 100%;
    /* background-image: url(https://pp.userapi.com/c851228/v851228813/91525/WJp3jHoX0Zs.jpg); */
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
  .sixthtemplate .profile-info {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
    padding-bottom: 70px;
  }
  .sixthtemplate .profile-text {
    font-size: 13px;
    line-height: 24.19px;
    margin-bottom: 50px;
  }
  .sixthtemplate .heading {
    margin: 0;
    padding-bottom: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .sixthtemplate .heading-light {
    color: #ffffff;
    border-bottom: 2px #5a5a5a dashed;
  }
  .sixthtemplate .contacts {
    margin-bottom: 70px;
  }
  .sixthtemplate .contacts-title {
    color: #fff;
    margin-bottom: 13px;
    font-size: 16px;
    font-weight: 400;
  }
  .sixthtemplate .contacts-text {
    color: #ababab;
    text-decoration: none;
    padding-left: 27px;
    line-height: 20.97px;
  }
  .sixthtemplate .contacts-item {
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 2px #5a5a5a dashed;
  }
  .sixthtemplate address {
    font-style: normal;
  }
  .sixthtemplate .fas {
    margin-right: 7px;
  }
  .sixthtemplate .languages {
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
  }
  .sixthtemplate .language {
    width: 100px;
    height: 100px;
    border: 6px solid #5c5c5c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
    margin-right: 30px;
  }
  .sixthtemplate .language:nth-child(3) {
    margin-bottom: 0;
  }
  .sixthtemplate .language-text {
    text-transform: uppercase;
    font-size: 11px
  }
  .sixthtemplate .languages-per {
    font-size: 15px;
    font-weight: 600;
  }
  .sixthtemplate .lines {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sixthtemplate .line {
    display: block;
    width: 90px;
    height: 2px;
    background-color: #5c5c5c;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sixthtemplate .line:nth-child(2) {
    width: 100px;
    margin-left: 20px;
  }
  .sixthtemplate .resume {
    padding: 25px 30px;
    flex-basis: 63%;
    background-color: #fff;
  }
  .sixthtemplate .resume-wrap {
    padding: 36px 56px;
    border: 1px solid rgba(168, 168, 168, 0.44);
    min-height: 100%;
  }
  .sixthtemplate .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 38px;
  }
  .sixthtemplate .logo-img {
    width: 90px;
    height: 90px;
    border: 1px solid #39383a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sixthtemplate .logo-lines {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 17px;
    margin-right: 17px;
  }
  .sixthtemplate .logo-line {
    width: 43px;
    height: 2px;
    background-color: #39383a;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sixthtemplate .logo-lines_left .logo-line:nth-child(2) {
    margin-right: 20px;
    width: 55px;
  }
  .sixthtemplate .logo-lines_right .logo-line:nth-child(2) {
    margin-left: 20px;
    width: 55px;
  }
  .sixthtemplate .about {
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e0e0;
    text-align: center;
    margin-bottom: 40px;
  }
  .sixthtemplate .name {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 10.75px;
    margin-bottom: 10px;
  }
  .sixthtemplate .position {
    display: inline-block;
    font-size: 9px;
    text-transform: uppercase;
    color: #808080;
    margin-bottom: 30px;
  }
  .sixthtemplate .about-address {
    font-size: 13px;
    margin-bottom: 15px;
    font-family: Roboto;
  }
  .sixthtemplate .about-contacts {
    font-size: 8px;
  }
  .sixthtemplate .about-contacts__link {
    text-decoration: none;
    color: #777777;
  }
  .sixthtemplate .heading_dark {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 37px;
  }
  .sixthtemplate .list {
    list-style: none;
    padding-left: 0;
  }
  .sixthtemplate .list-item {
    position: relative;
    padding-left: 40px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 2px dashed #ececec;
  }
  .sixthtemplate .list-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #000;
  }
  .sixthtemplate .list-item__title {
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }.sixthtemplate  .list-item__date {
    font-size: 10px;
    text-transform: uppercase;
  }
  .sixthtemplate .list-item__text {
    font-size: 10px;
    color: #777;
  }
  .sixthtemplate .list-item_non-border {
    border: none;
  }
  .sixthtemplate .heading_skills {
    margin-bottom: 48px;
  }
  .sixthtemplate .skills-list {
    list-style-type: none;
    padding-left: 0;
  }
  .sixthtemplate .skills-list__item {
    margin-bottom: 30px;
    text-transform: uppercase;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
  }
  .sixthtemplate .level {
    width: 70%;
    height: 8px;
    border: 1px solid #39383a;
    position: relative;
  }
  .sixthtemplate .level:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #898989;
  }
  .sixthtemplate .level-80:before {
    width: 80%;
  }
  .sixthtemplate .level-90:before {
    width: 90%;
  }
  .sixthtemplate .level-50:before {
    width: 50%;
  }
  
  @media (max-width: 1024px) {
    .sixthtemplate .container {
      width: 90%;
    }
  }
  
  @media (max-width: 992px) {
    .sixthtemplate .container {
      flex-direction: column;
      width: 70%;
    }
    .sixthtemplate  .profile-photo {
      width: 200px;
      height: 200px;
      border: 3px solid #fff;
      margin: auto;
      margin-top: 40px;
    }
    .sixthtemplate .profile {
      position: relative;
    }
    .sixthtemplate .profile:before {
      content: '';
      width: 100%;
      height: 150px;
      background-color: #03A9F4;
      display: block;
      position: absolute;
    }
    .sixthtemplate .profile-photo {
      position: relative;
      z-index: 0;
    }
    .sixthtemplate .lines {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .sixthtemplate .container {
      width: 80%;
    }
    .sixthtemplate .resume {
      padding: 10px;
    }
    .sixthtemplate .resume-wrap {
      padding-left: 20px;
      padding-right: 20px;
    }
    .sixthtemplate .list-item__title {
      font-size: 14px;
    }
    .sixthtemplate .list-item__date {
      font-size: 12px;
    }
    .sixthtemplate .list-item__text {
      font-size: 12px;
      line-height: 1.4;
    }
    .sixthtemplate .about-contacts__link {
      display: block;
      font-size: 13px;
    }
  }
  @media (max-width: 567px) {
    .sixthtemplate .logo-img {
      width: 70px;
      height: 70px;
    }
    .sixthtemplate .logo-lines {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  @media (max-width: 480px) {
    .sixthtemplate  .logo {
      display: none;
    }
    .sixthtemplate .container {
      min-width: 320px;
    }
    .sixthtemplate .name {
      letter-spacing: normal;
    }
    .sixthtemplate .level {
      width: 50%;
    }
    
  }
   
  /*======================================================== 6.html css end */
  
  /*======================================================== 7.html css start */
  
  .seventhtemplate * {
    /*   border: solid 1px; */
      box-sizing: border-box;
    }
    .seventhtemplate li {
      list-style-type: none;
    }
    .seventhtemplate ul {
      padding: 0px;
      display: inline-block;
    }
    
    .seventhtemplate .center {
      text-align: center;
    }
    .seventhtemplate body {
      background-color: #9593a8;
    }
    .seventhtemplate h2,
    h3,
    h4,
    .seventhtemplate .content p {
      color: #3a3560;
    }
    .seventhtemplate .grey {
      color: #838385;
    }
    .seventhtemplate h3 {
      padding-top: 10px;
      padding-bottom: 3px;
      font-size: 17px;
    }
    .seventhtemplate h4 {
      font-size: 15px;
    }
    .seventhtemplate p {
      font-size: 14px;
      margin-bottom: 40px;
      line-height: 18px;
    }
    .seventhtemplate .paper {
      width: 800px;
      margin-left: auto;
      margin-right: auto;
      background-color: #fafbff;
      padding: 1px;
      margin-top: 45px;
      box-shadow: 0px 4px 3px 5px #f8f8f8;
    }
    .seventhtemplate .paper2 {
      background-color: #fff;
    }
    
    .seventhtemplate .contact {
      display: inline-block;
      width: 70px;
      text-align: center;
      height: 1070px;
      vertical-align: top;
      padding-top: 440px;
    }
    .seventhtemplate .contact li {
      padding-top: 34px;
      padding-bottom: 34px;
    }
    .seventhtemplate .contact h3.grey {
      writing-mode: vertical-rl;
      color: #9ca8a8;
      padding-top: 70px;
      padding-bottom: 40px;
      padding-left: 5px;
    }
    .seventhtemplate .content {
      display: inline-block;
    }
    .seventhtemplate .left {
      display: inline-block;
      width: 45%;
      padding: 15px;
      box-sizing: border-box;
      margin-right: 5px;
      border-left: solid 2px #a8adb0;
      box-sizing: border-box;
      /* height: 1070px; */
      min-height: 90vh;
      vertical-align: top;
    }
    .seventhtemplate .left h2 {
      padding-top: 20px;
      padding-bottom: 20px;
      line-height: 35px;
      letter-spacing: 2px;
      font-size: 27px;
    }
    .seventhtemplate .right {
      display: inline-block;
      width: 52%;
      padding:30px;
      box-sizing: border-box;
      height: 1070px;
    }
    .seventhtemplate .pic {
      position: relative;
      /* margin-top: 40px; */
      /* padding-left: 15px; */
    }
    .seventhtemplate .colorbox {
      position: absolute;
      top: 357px;
      width: 345px;
      height: 50px;
      background-color: rgba(69, 73, 173, 0.78);
    }
    .seventhtemplate .colorbox2 {
      background-color: #93a0a8;
      position: absolute;
      top: 447px;
      left: 347px;
      width: 26px;
      height: 90px;
    }
    .seventhtemplate .colorbox3 {
      position: absolute;
      top: 410px;
      left: 130px;
    }
    .seventhtemplate .year h4 {
      display: inline-block;
    }
    .seventhtemplate .year .info {
      display: inline-block;
      text-align: left;
      width: 230px;
      font-size: 14px;
      vertical-align: middle;
      height: auto;
      margin-left: 14px;
    }
    .seventhtemplate .language {
      margin: -12px 0px;
      padding: 0px;
    }
    
    .seventhtemplate .language h4 {
      display: inline-block;
      margin-right: 10px;
      width: 83px;
    }
    .seventhtemplate .rate {
      vertical-align: middle;
      height: auto;
      display: inline-block;
    }
    .seventhtemplate .bigo {
      width: 12px;
      height: 12px;
      margin: 0px 3px;
      border-radius: 100%;
      background-color: #93a0a8;
      display: inline-block;
    }
    .seventhtemplate .smlo {
      width: 8px;
      height: 8px;
      margin: 2px 5px;
      border-radius: 100%;
      background-color: #4b4b4d;
      display: inline-block;
    }
    .seventhtemplate .skill {
      margin-top: 90px;
      height:auto;
      vertical-align:bottom;
    }
    .seventhtemplate .software {
      display: inline-block;
      width: 80px;
      height: 120px;
      text-align: center;
      position: relative;
      /*   margin:0;padding:0; */
    }
    .seventhtemplate .software h4 {
      display: block;
    /*   margin-top: 90px; */
    }
    .seventhtemplate .pie {
      width: 70px;
      height: 70px;
      background-color: #93a0a8;
      overflow: hidden;
      border-radius: 100%;
      position: absolute;
      left: 4px;
    }
    .seventhtemplate .cutpie1 {
      width: 70px;
      height: 70px;
      background-color: rgba(69, 73, 173, 1);
      position: absolute;
      transform-origin: bottom right;
      transform: rotate(0deg);
      left: 35px;
      top: -35px;
    }
    .seventhtemplate .cutpie2 {
      width: 70px;
      height: 70px;
      background-color: rgba(69, 73, 173, 1);
    /*   border:solid 1px; */
      position: absolute;
      transform-origin: bottom right;
      transform: rotate(30deg);
      left: 26px;
      top: 1px;
    }
    .seventhtemplate .cutpie2s {
      width: 70px;
      height: 70px;
      background-color: rgba(69, 73, 173, 1);
    /*   border:solid 1px; */
      position: absolute;
      transform-origin: bottom right;
      transform: rotate(150deg);
      left: -35px;
      top: -34px;
    }
    .seventhtemplate .cutpie3 {
      width: 70px;
      height: 70px;
      background-color: rgba(69, 73, 173, 1);
    /*   border:solid 1px; */
      position: absolute;
      transform-origin: bottom left;
      transform: rotate(240deg);
      left: 35px;
      top: -35px;
    }
    .seventhtemplate .software h4 {
      padding-top: 60px;
    }
    .seventhtemplate .skill h3 {
      vertical-align: middle;
      height: auto;
      margin-top:-30px;
    }
    .seventhtemplate .skillgroup {
      display: inline-block;
      text-align: right;
      width: 250px;
    /*   font-size: 14px; */
      vertical-align: middle;
      height: auto;
      margin-left: 0px;
    }
    
  /* css 3 new */

  @media print {
    @page
    {
        size: A4 ;
       
    }

    .newresumedivbox .container {
        box-shadow: none;
        margin: 0;
        padding: 0;
        background: none;
    }

    .newresumedivbox body {
        background: white;
        margin: 0;
    }

    .newresumedivbox .bg-dark {
        background-color: #333 !important;
        print-color-adjust: exact;
    }

   
    .newresumedivbox .row, .col-md-8, .col-md-4, .col-md-3, .col-md-9 {
         padding: initial;
        margin: none;
    }

    
    .newresumedivbox h2, h4, h5, p {
        font-size: inherit;
    }
}
  .newresumedivbox {
    max-width: 100%;
    padding: 0 15px; 
  }
  .newresumedivbox .namecontainer h2{
    color: white;
  }
  .newresumedivbox body {
            font-family: 'Arial', sans-serif;
            background-color: #f9f9f9;
            color: #333;
        }
        .newresumedivbox .aboutrow, .edurow, .exprow , .langrow{
            border-top: 2px solid #1b1b1b;
        }
  
        .newresumedivbox h2 {
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 2px;
        }
  
        .newresumedivbox h4 {
            font-weight: bold;
            margin-bottom: 15px;
            padding-bottom: 5px;
        }
  
        .newresumedivbox h5 {
            font-weight: bold;
            margin-top: 15px;
        }
  
        .newresumedivbox .bg-dark {
            background-color: #333 !important;
            color: white !important;
            text-align: left;
            padding: 30px;
        }
  
        .newresumedivbox .contact-info p {
            margin-bottom: 5px;
            font-size: 14px;
        }
  
        .newresumedivbox p {
            font-size: 15px;
            line-height: 1.6;
        }
  
        .newresumedivbox ul {
            padding-left: 20px;
            list-style-type: disc;
        }
  
        .newresumedivbox ul li {
            margin-bottom: 10px;
        }
  
        .newresumedivbox .container {
            background-color: #fff;
            padding: 30px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        }
  
  
    
  
  /*======================================================== 7.html css End */
  
        /* new media 10/10/2024 */

        @media print {
          @page {
            size: A4;
            margin: 0;
          }
          .newresumetemplate02 .resume {
          box-shadow: none;
          }
      }
      
      @media print {
          @page {
            size: A4;
            margin: 0;
          }
          
          body {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            height: 90vh;
            width: 100vw;
            margin: 0;
          }
           .newresumetemplate01 .resume{
              border: none;
          }
      }
      
      @media print {
          @page {
              size: A4;
              margin: 0;
            }
            
            body {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              height: auto;
              width: 100vw;
              margin: 0;
            }
          .newresumedivbox .container {
              box-shadow: none;
              margin: 0;
              padding: 0;
              background: none;
          }   
          .newresumedivbox h2, h4, h5, p {
              font-size: inherit;
          }
          .newresumedivbox .contact-info p {
              word-wrap: break-word !important;
          }
          .newresumedivbox .contact-info {
              width: 100%;
          }
          .newresumedivbox .contact-info span {
              word-break: break-word; 
          }
      }
      
      
      
      
      @media print {
          @page {
            size: A4;
            margin: 0;
          }
          
          body {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
            height: 100vh;
            width: 100vw;
            margin: 0;
          }
        
          .fourthtemplate .resume {
            width: 100%;
            height: 100%;
            display: flex;
            margin: 0; 
          }
          .resume_profile img{
            margin-bottom: 5px ;
            margin-top: 10px ;
      
          }
          .fourthtemplate .resume .resume_left {
            width: 280px; 
            min-height: 100%; 
            /* background: #0bb5f4;*/
          } 
        
          .fourthtemplate .resume .resume_right {
            padding: 25px; 
            height: 100vh;
          } 
        }
        @media print {
        
          @page {
            size: A4;
            margin: 0;
          }
          body {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
          }
          
          .fifthtemplate .container {
            width: 100%;
            max-width: none;
            margin: 0;
            box-shadow: none;
            min-height: 100vh;
            flex-direction: row; 
          }
          
          .fifthtemplate .container .left_Side {
            width: 36% !important;
           
          }
          
          .fifthtemplate .container .right_Side {
            width: 64% !important;
           
          }
        
          }
        
      
          
      
          @media print {
              @page {
                size: A4;
                margin: 0;
              }
            
              body {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
              }
            
              .firsttemplate .resume-container {
                width: 210mm; 
                height: 297mm;
                margin: 0;
                padding: 0;
                box-shadow: none;
              }
            
              .firsttemplate .row-print2 {
                display: flex !important;
                height: 100%;
                width: 100%;
              }
            
              .firsttemplate .left-section,
              .firsttemplate .right-section {
                height: 100%;
              }
            
              .firsttemplate .left-section {
                width: 40%;
                padding: 15mm;
              }
            
              .firsttemplate .right-section {
                width: 60%;
                padding: 15mm; 
                text-align: left;
              }
              .firsttemplate h5,.firsttemplate p, .firsttemplate li {
                text-align: left;
              }
              .firsttemplate .container {
                max-width: 100% !important;
              }
                }
/* resume 6 */


.newejobeeresume01 .header {
  background-color: #3498db;
  color: white;
  padding: 20px;
}

.newejobeeresume01 .prefrence p {
  line-height: 1.2;
  margin-bottom: 5px;
}

.newejobeeresume01 .header img {
  height: 150px;
  width: auto;
  border-radius: 50%;
 
}

.newejobeeresume01 .address p {
  line-height: 2.5;
  font-size: 1.2rem;
}

.newejobeeresume01 .nameheading{
  font-weight: 600;
  padding: 15px;
  color: white;
}
.newejobeeresume01 .nameheading .h2, h2{
  line-height: 1.5;
}

.newejobeeresume01 .headding-title {
  color: #2b88c5;
  border-bottom: 1.5px solid rgb(175, 175, 175);
}

@media only screen and (max-width: 600px) {
  .newejobeeresume01 .header {
      text-align: center; 
  }
}
@media print {
  

.signature_resm{
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}
  @page {
      margin: 0;
  }
  .newejobeeresume01 {
      width: 100vw;
      height: 90vh;
      max-width: 100%;
      margin: 0 !important;
      padding: 0 !important;
      padding-bottom: 100px;
      font-size: 10pt;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      position: relative;
  }
  .newejobeeresume01 .row {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
  }
  .newejobeeresume01 .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
  }
 
  /* .newejobeeresume01 .signature {
      position: absolute;
      bottom: 50px;
      left: 0;
      width: 100%;
  }       */
      * {
          -webkit-print-color-adjust: exact !important;
          color-adjust: exact !important;
          print-color-adjust: exact !important;
      }
      .newejobeeresume01 .header.row {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem !important;
          width: 100%;
          padding: 5px;
          box-sizing: border-box;
      }
      .newejobeeresume01 .header .col-md-2 {
          flex: 0 0 16.666667%;
          max-width: 16.666667%;
          padding: 0 10px;
      }
  
      .newejobeeresume01 .header .col-md-2 img {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 50%;
          display: block;
      }
      .newejobeeresume01 .header .col-md-3 {
          flex: 0 0 25%;
          max-width: 25%;
      
      }
  
      .newejobeeresume01 .header .nameheading h2 {
          font-size: 24pt;
          font-weight: bold;
          margin: 0;
          line-height: 1.2;
          color: white;
      }
      .newejobeeresume01 .header .col-md-7 {
          flex: 0 0 58.333333%;
          max-width: 58.333333%;
          padding: 0 15px;
      }
  
      .newejobeeresume01 .header .address p {
          font-size: 11pt;
          line-height: 2.5;
          margin: 0;
          white-space: nowrap;
          padding: 15px 15px;
      }
      .newejobeeresume01 .signature {
          position: fixed; 
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 20px;
          margin-top: 20px;
          page-break-before: avoid;
          page-break-inside: avoid;
      }
  
  }
/* comp resm 7 */
.newejobeeresume02 .resume {
  max-width: 800px;
  margin: auto;
  /* padding: 20px; */
  background: #fff;
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.newejobeeresume02 .header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #003366;
  padding-bottom: 10px;
  background-color: #333e50;
  color: white;
}
.newejobeeresume02 .header img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  margin-top: 10px;
}
.newejobeeresume02 .section-title {
  color: #003366;
  margin-top: 20px;
  border-bottom: 1.5px solid #003366;
}

.newejobeeresume02 .prefrence p {
    line-height: 1.2; 
    margin-bottom: 5px;
    }
   
@media only screen and (max-width: 600px) {
.newejobeeresume02 .information_div {
text-align: center;
}
.newejobeeresume02 .information_div p span{
display: block;
}
.newejobeeresume02 .information_div p br {
display: none;
}
.newejobeeresume02 .header img {
display: block;
margin-left: auto;
margin-right: auto;
}

}
@media print {
@page {
    margin: 0;
    size: 100%;
}

body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.newejobeeresume02 {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
}

.newejobeeresume02 .resume {
    width: 100%;
    max-width: none;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none;
    position: relative;
}

.newejobeeresume02 .header {
    background-color: #333e50 !important;
    color: white !important;
    border-bottom: 2px solid #003366;
    padding: 10px;
    height: auto;
    min-height: 170px;
    width: 100%;
    box-sizing: border-box;
}

.newejobeeresume02 .section-title {
    color: #003366 !important;
    margin: 10px 0;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
}

.newejobeeresume02 .header img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-left: 15px;
}

.newejobeeresume02 .prefrence p {
    line-height: 1.2;
    margin-bottom: 5px;
}

.newejobeeresume02 .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
}

.newejobeeresume02 .skills,
.newejobeeresume02 .lang {
    margin-left: 10px;
}

.newejobeeresume02 .col-md-6,
.newejobeeresume02 .col-md-9,
.newejobeeresume02 .col-md-3 {
    float: none;
    display: inline-block;
    box-sizing: border-box;
    padding: 5px;
}

.newejobeeresume02 .col-md-6 { width: 50%; }
.newejobeeresume02 .col-md-3 { width: 25%; }
.newejobeeresume02 .col-md-9 { width: 75%; }

.newejobeeresume02 .section,
.newejobeeresume02 .work-item {
    page-break-inside: avoid;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.newejobeeresume02 .mb-2,
.newejobeeresume02 .mb-3 {
    margin-bottom: 5px !important;
}

.newejobeeresume02 .signature {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: white;
    box-sizing: border-box;
}

* {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
}
}


/* 11/10/2024 */
/* =============================================== basic 1 */

@media (max-width: 480px) {
  .newresumetemplate02 .header, .newresumetemplate02 .personal-info,.newresumetemplate02 .resume {
      align-items: center;
      width: auto;
      flex-direction: column;
    }
  .newresumetemplate02 .personal-info h1 {
    font-size: 20px;
  }

  .newresumetemplate02 .personal-info p,
  .newresumetemplate02 .section p {
    font-size: 13px;
  }

  .newresumetemplate02 .section h2 {
    font-size: 15px;
  }

  .newresumetemplate02 .photo img {
    height: auto;
    width: 100%;
    max-width: 120px;
  }
}



/* =============================================== basic 2 */

@media (max-width: 480px) {
  .newresumetemplate01 .resume {
    padding: 5px;
    flex-direction: column;
    width: 100% !important;
  }
  .newresumetemplate01 .resume .left-section,.newresumetemplate01 .resume .right-section{
    width: 100%;
  }

  .newresumetemplate01 .left-section img {
    width: 120px;
  }

  .newresumetemplate01 h2 {
    font-size: 15px;
  }

  .newresumetemplate01 .section-title {
    font-size: 0.85rem;
  }

  .newresumetemplate01 .basic-info p,
  .newresumetemplate01 .skills p,
  .newresumetemplate01 .right-section p {
    font-size: 13px;
  }

  .newresumetemplate01 .heading-sectionresume h2 {
    font-size: 15px;
  }

  .newresumetemplate01 .heading-sectionresume p {
    font-size: 13px;
  }
}


/* =============================================== basic 3 */


@media (max-width: 480px) {  
  .newresumedivbox .container11 .mx-4 {
      margin-left: 0 !important;
  }

  .newresumedivbox .container11 ,  .newresumedivbox .container11 .row,.newresumedivbox .container11 .namecontainer, .newresumedivbox .container11 .col-4{
  align-items: center;
  width: 100%;
  flex-direction: column;
  }
.newresumedivbox .namecontainer h2 {
  font-size: 20px;
}

.newresumedivbox .contact-info h4 {
  font-size: 12px;
}

.newresumedivbox h4 {
  font-size: 16px;
}

.newresumedivbox h5 {
  font-size: 14px;
}

.newresumedivbox p {
  font-size: 12px;
}
}



/* =============================================== premium 1 */

@media only screen and (max-width: 480px) {
  .fourthtemplate .resume {
    flex-direction: column;
    width: 100%; 
    margin: 0;
    padding-right: 5px;
  }
  
  .fourthtemplate .resume .resume_left {
    width: 100%;
  }
  
  .fourthtemplate .resume .resume_right {
  width: auto;
  background: #fff;
  padding: 25px;
  }
  .fourthtemplate .resume .resume_right {
  padding: 0px;
  }
  .fourthtemplate .resume .resume_left .resume_content{
    padding: 0;
  }
  .fourthtemplate .resume_left, .fourthtemplate .resume_right {
    width: 100%; 
  }

  .fourthtemplate .resume_left {
    margin-bottom: 20px; 
  }

  .fourthtemplate .resume_right {
    padding-right: 5px;
  }
  .fourthtemplate .resume .resume_profile img {
    width: 80px; 
    height: auto;
    display: block;
    margin: 0 auto 10px auto;
  }

  .fourthtemplate .resume .resume_skills ul li .skill_name, 
  .fourthtemplate .resume .resume_skills ul li .skill_progress,
  .fourthtemplate .resume .resume_skills ul li .skill_per {
    display: block;
    margin-bottom: 10px;
  }

  .fourthtemplate .resume_right .resume_hobby ul {
    flex-direction: column;
    padding: 0;
  }

  .fourthtemplate .resume_right .resume_hobby ul li {
    margin-bottom: 15px;
  }
  
  
  body, html {
    overflow-x: hidden;
    font-size: 15px;
  }
}



/* =============================================== premium 2 */

@media (max-width: 480px) {
  .fifthtemplate .container {
    flex-direction: column; 
    min-height: auto;
    margin: 0;
    
  }
  
  .fifthtemplate .left_Side {
    width: 100% !important;
  }
  
  .fifthtemplate .right_Side {
    width: 100% !important; 
    min-height: auto;
    padding: 20px; 
  }
  
  .fifthtemplate .profileText .imgBx {
    width: 150px; 
    height: 150px;
  }
  
  .fifthtemplate h2 {
    font-size: 1.2em;  
  }
  
  .fifthtemplate .about .box {
    flex-direction: column; 
    margin: 10px 0;
  }
  
  .fifthtemplate .about .box .year_company {
    padding-right: 0;
    margin-bottom: 10px;
  }
  
  .fifthtemplate .skills .box {
    grid-template-columns: 1fr; 
  }
  
  .fifthtemplate .contactInfo ul {
    padding-left: 0;
  }
  
  .fifthtemplate .contactInfo ul li {
    align-items: center;
    justify-content: space-between;
  }
  
  .fifthtemplate .interest ul {
    grid-template-columns: 1fr 1fr; 
  }
  }
  
   /* =============================================== premium 3 */

   @media (max-width: 480px) {

      .firsttemplate .resume-container {
        margin: 0; 
        text-align: center;
      }
    
      .firsttemplate .left-section,
      .firsttemplate .right-section {
        padding: 0;
        width: 100%; 
      }
    
      .firsttemplate .row-print2 {
        flex-direction: column;
      }
    
      .firsttemplate .left-section {
        min-height: auto; 
        margin-bottom: 20px; 
            
      }
    
      .firsttemplate img {
        width: 120px;
        margin: 0 auto 10px; 
      }
    
      .firsttemplate h2, .firsttemplate h4 {
        font-size: 1.2rem; 
      }
    
      .firsttemplate h5, .firsttemplate p {
        font-size: 0.9rem;
      }
    }



    