.custom-footer {
    background-color: #2867B2;
    color: white;
    padding: 20px 0;
  }
  .custom-footer a {
    color: white;
    text-decoration: none;
    margin-right: 15px;
  }
  .custom-footer a:hover {
    text-decoration: underline;
  }
  .custom-footer {
      align-items: center;
  }