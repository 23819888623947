.ejobeeloginmodal .modal-title img{
    height: 40px;
}
.ejobeeloginmodal .modal-content {
    /* max-height:400px;  */
}
.ejobeeloginmodal .card-text img{
    /* height: 150px; */
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.ejobeeloginmodal .card{
    border: 2px solid rgb(223, 222, 222);
}
.ejobeeloginmodal .card {
    transition: border 0.3s ease; 
}
.ejobeeloginmodal .modal-header{
    background-color:transparent;
}
.ejobeeloginmodal .card-body h5,.ejobeeloginmodal .card-body p {
    font-weight: 700;
}
.ejobeeloginmodal .card:hover {
   box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.ejobeeloginmodal .card .card-title:hover,
.ejobeeloginmodal .card .card-text:hover,
.ejobeeloginmodal .card .btn:hover {
    border: none; 
}
@media (max-width: 600px) {
    .ejobeeloginmodal .modal-content {
        /* max-height: 550px;  */
    }
}