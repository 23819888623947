/* Webpixels CSS */
/* Utility and component-centric Design System based on Bootstrap for fast, responsive UI development */
/* URL: https://github.com/webpixels/css */

@import url(https://unpkg.com/@webpixels/css@1.1.5/dist/index.css);

/* Bootstrap Icons */
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");


.ck-editor__editable {
    min-height: 500px !important;
}

.navbar-nav::-webkit-scrollbar{
    width: 2px;
}

.navbar-nav::-webkit-scrollbar-thumb{
    background-color: var(--primary-color);
    border-radius: 10px;
}

.h-200{
    height: 200px !important;
}