
.introvid video{
    height: 232px;
    width: 100%;
}
.fa-check-circle{
    color: rgb(48, 192, 48);
}

.profile-img img{
/* height: 232px; */
width: 100%;
}
.candidate_info-main{
    background-color: transparent;
}

.candidateeducation li{
    list-style: none;
    border: 1px solid rgb(194, 193, 193);
    border-radius: 5px;
}
.candidateexperience li{
    list-style: none;
    border: 1px solid rgb(194, 193, 193);
    border-radius: 5px;

}

.profile-user-img {
    border: 3px solid #adb5bd;
    margin: 0 auto;
    padding: 3px;
    width: 100px;
}


.ribbon2 {
    position: absolute;
    top: 10px; /* Distance from the top */
    left: -40px; /* Adjust to center the ribbon */
    width: 150px; /* Width of the ribbon */
    padding: 10px 0;
    background-color: #28a745!important;
    color: white;
    text-align: center;
    font-weight: bold;
    transform: rotate(-45deg);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  }

  .ribbon2-right {
    position: absolute;
    top: 42px;
    right: -8px;
    width: 90px;
    padding: 10px 0;
    background-color: #28a745 !important;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    transform: rotate(50deg);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}
.ribbon-wrapper {
    height: 70px;
    overflow: hidden;
    position: absolute;
    right: -2px;
    top: -2px;
    width: 70px;
    z-index: 10;
}
.ribbon-wrapper .ribbon::after {
    right: 0;
}
.ribbon-wrapper .ribbon {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    font-size: .8rem;
    line-height: 100%;
    padding: .375rem 0;
    position: relative;
    right: -2px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
    text-transform: uppercase;
    top: 10px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 90px;
}

.ribbon-wrapper .ribbon::after, .ribbon-wrapper .ribbon::before {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 3px solid #9e9e9e;
    bottom: -3px;
    content: '';
    position: absolute;
}