
.services a {
  text-decoration: none;
}
.services1 .serviceBox {
  text-align: center;
}
.services .serviceBox h2 {
  color: #222;
  font-size: 20px;
  padding-top: 50px;
  text-decoration: none;
}
.services a .serviceBox:hover h2 {
  color: #FB0626;
}
.services .investor-box {
  /* background-color: #40c3eb; */
  background-position: center center;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  min-height: 150px;
  display: block;
  position: relative;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.services .investor-box h2 {
  font-size: 20px;
}
.services .investor-box .flip-view {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #A3D8FF;
  left: -10%;
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 333ms;
}
.services .investor-box a {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.services .investor-box:hover .flip-view {
  left: 0;
  visibility: visible;
  opacity: 1;
}
.investor-box {
  text-align: center;
  /* background-image: linear-gradient(to right, #474bff, #474bff 10px, transparent 10px, transparent); */
  background-size: 260px;
  background-color: #47d3ff;
  
}
.investor-box h2 {
  margin: 0 auto;
}
.investor-box i {
  font-size: 50px;
  /* color: rgb(105, 105, 105); */
  color: #32499a;
}
.it{
  background-color: #7fffd4 ;
}
.it h2{
  color: #0c5f43;
  font-weight: 700;
}
.banking{
  background-color: #D1E9F6;
} 
.banking h2{
  color: #23495e;
  font-weight: 700;
}
.accounting{
  background-color: #F6EACB;
}
.accounting h2{
  color: #634f1d;
  font-weight: 700;
}
.graphic{
  background-color: #F1D3CE;
}
.graphic h2{
  color: #b34343;
  font-weight: 700;
}
.customer{
  background-color: #e08ba4;
}
.customer h2{
 color: #6b3344;
 font-weight: 700;
}
.digital{
  background-color: #ccbbc7;
}
.digital h2{
  color: #2b4d2e;
  font-weight: 700;
}
.hr{
  background-color: #BEDC74;
}
.hr h2{
  color: #4a552f;
  font-weight: 700;
}
.alljob{
  background-color: #F7B5CA;
}
.alljob h2{
  color: #5f2c3c;
  font-weight: 700;
} 