.bg_job_code{
    background-color: #fff;
    padding: 2px 2px;
    border-radius: 7px 7px 7px 0px;
    font-size: 13px;
    font-weight: bold;
    color: #000;
    text-align: left;
     margin: 7px 0 7px;
}

.bg_job_skills{
    background-color: #61666842;
    padding: 2px 4px;
    
    /* border-radius: 7px 7px 7px 0px; */
    font-size:13px;
    /* font-weight: bold; */
    /* color: #1ca8d8; */
    margin: 7px 2px 20px;
}