@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.5.0/css/all.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css');


@import url(./assets/css/vendor/vendor.min.css);
@import url(./assets/css/plugins/plugins.min.css);
/* @import url(./assets/css/style.min.css); */
@import url(./assets/css/aos.css);
/* global css */
.App {}

:root {
  --primary-color: #2867B2;
  --color-dark: black;
  --color-light: white;
}


/* media queries */
@media (max-width:768px) {
  .reverse {
    flex-direction: column-reverse !important;
  }
}
@media(max-width:768px){
  .min-heigh-mob{
    min-height: 600px !important;
  }
}

.dropdown-item:active{
  color: var(--primary-color);
}

section {
  padding: 50px 0px;
}

.job-detail-bg {
  background-color: var(--primary-color) !important;
  color: var(--color-light);
}



/* section title */
.section-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-title h4{
  color: var(--primary-color);
  text-transform: capitalize;
}

.section-title a{
  text-decoration: underline;
}

.section-title a:hover{
  text-decoration: underline;
}

/* input search */
.job-title-input {
  z-index: 99 !important;
}

.job-city-input {
  z-index: 99 !important
}


/* form control */
.form-group .label {
  margin-bottom: 5px;
  font-weight: 400;
}

.form-group .required {
  color: red;
}

.form-group .input-error {
  color: red;
}

.form-control {
  height: 45px !important;
  padding: 0px 10px !important;
  
  width: 100%;
}

.form-control:focus {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.form-control:focus .react-tel-input .flag-dropdown {
  border-color: var(--primary-color) !important;
}

.phoneinput {
  padding-left: 50px !important;
}

/* background color */
.bg-color {
  background-color: var(--primary-color);
}
.green-color{
  color:green;
}

/* btns */

.login_btn {
  padding: 8px 25px !important;
  border-radius: 5px !important;
}

.login_btn:hover {
  /* background-color: #B22868; */
  background-color: var(--primary-color) !important;
  color: var(--color-light) !important;
}

.btn-primary {
  border-radius: 5%;
  background-color: #B22868 !important;
  /* background-color: var(--primary-color) !important; */
  transition: 0.01s ease-in-out;
  
}

.btn-primary:hover {
  background-color: transparent;
  color: var(--color-light) !important;
}

.btn-secondary {
  border-radius: 0 !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
  transition: 0.01s ease-in-out;
  border-color: var(--primary-color) !important;
}

.btn-secondary:hover {
  background-color: var(--primary-color) !important;
  color: var(--color-light) !important;
}

/* text and links */
a {
  color: var(--color-dark);
  text-decoration: none;
}

li {
  list-style: none;
}


/* web and candidate css */

/* navbar */
nav {
  position: relative;
  width: 100%;
  padding: 13px 0px;
  transition: 0.2s;
  background-color: var(--color-light);
  /* box-shadow: 0px 1px 2px #999; */
  box-shadow: 0px 1px 2px #B22868;
  z-index: 999;
}

nav .nav-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* sidebar */

.sidebaractive {
  height: 100vh;
  overflow: hidden;
}

.backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.4);
}

.web-sidebar {
  width: 0%;
  height: 100%;
  position: absolute;
  z-index: 9999;
}

.web-sidebar.active {
  width: 100%;
}

.web-sidebar .side-bar {
  width: 70%;
  overflow: hidden;
  background-color: var(--color-light);
  height: 100%;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, #999);
}

.web-sidebar .side-bar .side-top {
  padding: 20px 0px;
}

.web-sidebar .side-bar .side-top .logo img {
  width: 150px !important;
}

.web-sidebar .side-bar .side-links {
  margin-top: 20px;
}

.web-sidebar .side-bar .side-links ul {
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.web-sidebar .side-bar .side-links ul li a {
  font-weight: 500;
  font-size: 20px;
}

/* otp */
.otpcontainer {
  width: 100%;
  display: flex;
  gap: 10px;
}

.otpinputclass {
  border: 1px solid black;
  border-radius: 0px;
  flex-basis: 10%;
  height: 40px;
  color: black;
}

@media (max-width:991px) {
  .otpinputclass {
    flex-basis: 15%;
  }
}

/* menu icon */
nav .menu-icon {
  display: none;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 5px;
}

@media (max-width:768px) {
  nav .menu-icon {
    display: flex;
  }
}

nav .menu-icon .menu-line {
  background-color: var(--primary-color);
  width: 25px;
  height: 2px;
}

.floatingNav {
  box-shadow: 0px 1px 10px #999;
  position: fixed;
}

nav .nav-left .logo img {
  width: 130px !important;
}

nav a {
  color: var(--primary-color);
  font-weight: 500;
}

nav ul {
  display: flex;
}

@media (max-width:768px) {
  nav ul {
    display: none;
  }

  
.hero-section{
  padding-top: 0px;
  background-image: url('./c_assets/images/n_mob_banner.gif');
  min-height: 400px;
  /* background-image: url('./c_assets/images/banner_1.jpg'); */
  /* background-repeat: repeat-y;  */
  background-size: cover;
  /* object-fit: contain; */
  background-repeat: no-repeat;
  position:relative; 
  /* background-position: right; */
  
  
}
}

@media(min-width:700px){
  /* hero section */

.hero-section{
  padding-top: 0px;
  background-image: url('./c_assets/images/bannergif.gif');
  min-height: 500px;
  /* background-image: url('./c_assets/images/banner_1.jpg'); */
  /* background-repeat: repeat-y;  */
  background-size: contain;
  position:relative; 
  /* background-position: right; */
  
  
}
}


.hero-section img{
  display:block;
  opacity: 1;
  bottom: 0px;
  width: 100%;
}
.hero-section .hero-text {
  position: relative;
}
.center{
  text-align: center;
}
.hero-text h1{
  color:#2867b2;
}

.hero-section .div-search{
  position: absolute;
    top: 100%;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    /* color: white;  */
    /* font-size: 24px;  */
    /* text-align: center; */
    /* background-color: rgb(244 242 242 / 69%); */
    padding: 10px; /* Optional: padding around the text */
    border-radius: 5px; /* Optional: rounded corners for the background */
    width: 100%;
    max-width: 100%;
}

.hero-section .hero-text {
  padding: 20px 20px;
}

@media (max-width:991px) {
  .hero-section .hero-text {
    padding: 5px 5px;

  }
  .hero-section .div-search{
    width: 100%;
    /* height: 400px; */
    /* background-color: rgb(244 242 242 / 90%); */
  }
  .hero-section{
    /* min-height: 400px; */
  }
  .hero-section img{
    /* display: none; */
  }
}



@media (max-width:991px) {
  .hero-section{
    /* padding-top: 50px; */
  }
}

.hero-text {
  display: flex;
}

.hero-text a {
  /* color: var(--primary-color); */
  color:#b22868;
  font-weight: bold;
  font-size: 18px;
}

.hero-text h1 {
  font-size: 48px;
  letter-spacing: 3px;
  text-transform: capitalize;
}

@media (max-width:991px) {
  .hero-text h1 {
    /* font-size: 30px; */
    font-size: 19px;
  }
  
}

.hero-text h1 span {
  color: var(--primary-color);
  font-weight: bolder;
}


/* latest job section */




/* about section */
/* empty */


/* resume section */
.resume-box {
  padding: 0px 200px;
  text-align: center;
  display: flex;
  align-items: center;
}

@media (max-width:768px) {
  .resume-box {
    padding: 0px;
  }

}

.resume-box h2 {
  position: relative;
  color: var(--color-light);
  font-weight: bolder;
  display: flex;
  padding-bottom: 15px;
  justify-content: center;
  text-transform: uppercase;
}

.resume-box h2::after {
  content: '';
  width: 200px;
  height: 2px;
  background-color: var(--color-light);
  position: absolute;
  bottom: 0;
}

.resume-box p {
  color: var(--color-light);
}

.resume-btn {
  background-color: var(--color-light) !important;
  border-color: var(--color-light) !important;
  color: var(--primary-color) !important;
  font-weight: bolder;
}

.resume-btn:hover {
  color: var(--primary-color) !important;
  border-color: var(--color-light) !important;
}

/* testimonial section */
.testimonial-section {
  display: flex;
}

.testimonial-section h1 {
  text-transform: capitalize;
  font-size: 2.4rem;
  margin-bottom: 2rem;
  font-weight: 700;
}

.testimonial-section .cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media (max-width:768px) {
  .testimonial-section .cards {
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: scroll;
  }
}

.testimonial-section .cards .card {
  cursor: pointer;
  padding: 2em;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 2.188rem;
  align-items: center;
  transition: 0.3s;
  position: relative;
  border: 0.094rem solid transparent;
}

.testimonial-section .cards .card img {
  display: block;
  width: 3.35rem;
  height: 3.35rem;
  border-radius: 50%;
  filter: grayscale(1);
  transition: 0.5s;
}

.testimonial-section .cards .card h3 {
  text-transform: capitalize;
  font-size: 1.025rem;
}

.testimonial-section .cards .card p {
  text-transform: capitalize;
  color: #767676;
  font-size: 0.9rem;
}

.testimonial-section .cards .card.active {
  background: #fff;
  border: 0.094rem solid #0f172a14;
}

.testimonial-section .cards .card.active .gradient {
  background-image: linear-gradient(to right,
      #4755690a,
      #4343c899,
      #4343c899,
      #4755690a);
  width: 50%;
  height: 0.094rem;
  position: absolute;
  content: "";
  bottom: -0.063rem;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0.125rem 0.75rem #4343c84d;
}

.testimonial-section .cards .card.active img {
  filter: grayscale(0);
}

.testimonial-section .content {
  position: relative;
  width: 100%;
  overflow: inherit;
  margin-top: 2rem;
}

.testimonial-section .content .contentBox {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  display: none;
}

.testimonial-section .content .contentBox.active {
  display: block;
}

.testimonial-section .content .contentBox .text {
  padding-bottom: 2rem;
}

.testimonial-section .content .contentBox h2 {
  transition: 0.5s;
  opacity: 0;
}

.testimonial-section .content .contentBox p {
  transition: 0.5s;
  opacity: 0;
  margin-top: 1.25rem;
}

.testimonial-section .content .contentBox span {
  display: inline-block;
  transition: 0.5s;
  opacity: 0;
  margin-top: 0.625rem;
}

.testimonial-section .content .contentBox span svg {
  width: 1.25rem;
  color: #eca633;
}

.testimonial-section .content .contentBox.active h2 {
  opacity: 1;
  transition-delay: 0.5s;
}

.testimonial-section .content .contentBox.active span {
  opacity: 1;
  transition-delay: 0.7s;
}

.testimonial-section .content .contentBox.active p {
  opacity: 1;
  transition-delay: 0.9s;
}

/* faq */
.faq-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.faq {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  transition: all .4s ease;
}

.faq.active {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}

.faq-title {
  font-size: 18px;
  margin: 0 35px 0 0;
}

.faq-text {
  display: none;
  margin: 10px 0 0;
}

.faq.active .faq-text {
  display: block;
}

.faq-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 5px;
  right: 20px;
  height: 30px;
  width: 30px;
}

.chevron,
.close {
  width: 12px;
  height: 12px;
}

.faq-toggle .close {
  display: none;
}

.faq.active .faq-toggle .close {
  display: block;
}

.faq.active .faq-toggle .chevron {
  display: none;
}

/* blog csss */
section .blog .blog-img {
  width: 100%;
}

section .blog .blog-img img {
  width: 100%;
}

section .blog .blog-content {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 10px;
}

section .blog .blog-content .blog-title {
  color: var(--primary-color);
}

section .blog .blog-content .blog-desc {
  color: #a8a8a8;
}

section .blog .blog-content .blog-details {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

section .blog .blog-content .blog-details .blog-author-img {
  display: flex;
  align-items: center;
}

section .blog .blog-content .blog-details .blog-author-img img {
  width: 50px;
}

section .blog .blog-content .blog-details .blog-author-details .blog-author-desig {
  font-size: 13px;
  color: #888888;
}

section .blog .blog-content .blog-details .blog-author-details .blog-author-name {
  color: var(--primary-color);
}

/* footer */
footer {
  padding: 50px 0px;
  box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.2);
  
}
footer {
  /* position: fixed;
  bottom: 0;
  left: 0;
  width: 100%; */
  /* background-color: #333; */
  /* color: white; */
  /* text-align: center; */
  /* padding: 15px 0; */
  /* box-sizing: border-box; */
}

footer ul {
  padding-left: 0;
}

footer a {
  font-weight: 500;
}

footer .footer-brand {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

footer .footer-brand .logo {
  width: 200px;
}

footer .footer-brand .socials {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

footer .footer-brand .socials .icon {
  background-color: var(--color-light);
  padding: 6px 8px;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

footer .footer-brand .socials .icon svg {
  fill: var(--primary-color) !important;
}

footer .footer-brand .socials .icon:hover svg {
  fill: white !important;
}

footer .footer-brand .socials .icon:hover {
  background-color: var(--primary-color);
}

@media (max-width:991px) {
  .footer-links:first-child {
    margin-top: 10px;
  }
}

/* login */
.main-login {
  /* height: 100vh;
  overflow: hidden; */
}

.login-switch {
  padding: 5px 20px;
  font-weight: 500;
  border-radius: 2px;
  color: var(--primary-color);
  border-color: var(--primary-color);
  cursor: pointer;
  border-radius: 2px !important;
  transition: 0.2s ease-in-out;
}

.login-switch.active {
  background-color: var(--primary-color);
  color: var(--color-light);
}


/* jobs page */

.search-bar {
  border-radius: 24px;
  border: 1px solid rgb(223, 225, 229);
  background: white
}

.search-bar .job-title-input {
  flex-basis: 70%;
}

.search-bar .divider {
  width: 1px;
  display: block;
  background: var(--primary-color);
  height: 30px
}

.search-bar .job-city-input{
  flex-basis:45%;
}

@media (max-width:991px) {
  .search-bar .job-title-input{
    flex-basis: 100%;
  }

  .search-bar .divider {
    display: none;
  }

  .search-bar .job-city-input{
    display: none;
  }

}

.search-bar:hover,
.search-bar:focus-within {
  box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
}

/* contact us page */
.main-contact-us .card {
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  cursor: pointer;
}

.main-contact-us .card h5 {
  color: var(--primary-color);
  margin-bottom: 0;
}

.main-contact-us .card p {
  margin-bottom: 0;
}

.main-contact-us .contact-form {
  background-color: white;
  padding: 30px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
  ;
}

.main-contact-us .contact-form input {
  padding-left: 10px !important;
}

.main-contact-us .contact-form textarea {
  padding-left: 10px !important;
  height: 100px !important;
}

/* jobs */
.job-row {
  row-gap: 20px;
}

/* resume page */
.resume-page input {
  padding-left: 10px !important;
}

/* profile page */

/* candidate profile */

.main-can-profile .tab-bar::-webkit-scrollbar {
  width: 0;
}

.main-can-profile .tab-bar .col-lg-2 {
  padding-right: 0px !important;
}

.main-can-profile .profile {
  background-color: white;
  display: flex;
  border-radius: 10px;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.main-can-profile .profile .profile-pic {
  display: flex;
  position: relative;
  height: fit-content;
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
} 

.main-can-profile .profile .profile-pic img{
  height: 100%;
  object-fit: contain;
}

.main-can-profile .profile .profile-pic label {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer !important; 
}

.main-can-profile .profile .profile-detail {
  display: flex;
  gap: 2px;
  flex-direction: column;
}

.main-can-profile .profile .profile-detail .exp {
  display: flex;
  gap: 5px;
}

.main-can-profile .profile .profile-detail .loc {
  display: flex;
  gap: 5px;
}

.main-can-profile .profile .profile-detail p {
  margin-bottom: 0px;
}

.main-can-profile .profile .profile-detail .profile-name {
  color: black;
  font-weight: 25px;
  font-weight: bold;
}

/* work exp */

.main-can-profile .work-exp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-can-profile .work-exp .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main-can-profile .work-exp .header .title {
  color: black;
  font-size: 20px;
  font-weight: 500;
}

.main-can-profile .work-exp .exp-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main-can-profile .work-exp .exp-body .t-exp {
  display: flex;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.main-can-profile .work-exp .exp-body .t-exp-v {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.main-can-profile .work-exp .exp-body .t-exp .title {
  color: black;
}

.main-can-profile .personal {
  background-color: white;
  display: flex;
  border-radius: 10px;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.main-can-profile .personal p {
  margin-bottom: 0px;
}



.loading-loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid var(--primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* exp-detail */

.main-can-profile .work-exp .exp-detail {
  display: flex;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.main-can-profile .work-exp .exp-detail .exp-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-can-profile .work-exp .exp-detail .exp-icon .point-line {
  border-left: 1px dashed rgb(209, 206, 212);
  height: 50px;
}

.main-can-profile .work-exp .exp-detail .exp-icon .point {
  color: rgb(209, 206, 212);
  font-size: 48px;
  margin-top: -30px;
}

.main-can-profile .work-exp .exp-detail .exp-d {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.main-can-profile .work-exp .exp-detail .exp-d .exp-d-sub {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-can-profile .work-exp .exp-detail .exp-d .exp-d-sub .exp-d-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-can-profile .work-exp .exp-detail .exp-d .exp-d-sub .exp-t {
  font-weight: bold;
}

.main-can-profile .work-exp .exp-detail .exp-d .exp-sub-t {
  font-weight: 400;
  color: rgb(140, 133, 148);
  font-size: 14px;
  line-height: 16px;
}

.main-can-profile .work-exp .exp-detail .exp-d .exp-b {
  font-weight: bold;
}

.main-can-profile .work-exp .exp-detail .exp-d .exp-opt span {
  flex-shrink: 0;
  background-color: rgb(244, 242, 246);
  border: 1px solid rgb(232, 231, 234);
  color: rgb(130, 116, 133);
  border-radius: 6px;
  padding: 6px 8px;
  font-weight: 400;
  display: flex;
  align-items: center;
  font-size: 10px;
}

/* edu detail */

.main-can-profile .work-exp .edu-detail {
  display: flex;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.main-can-profile .work-exp .edu-detail .exp-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-can-profile .work-exp .edu-detail .exp-icon .point-line {
  border-left: 1px dashed rgb(209, 206, 212);
  height: 25px;
}

.main-can-profile .work-exp .edu-detail .exp-icon .point {
  color: rgb(209, 206, 212);
  font-size: 48px;
  margin-top: -30px;
}

.main-can-profile .work-exp .edu-detail .exp-d {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.main-can-profile .work-exp .edu-detail .exp-d .exp-d-sub {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-can-profile .work-exp .edu-detail .exp-d .exp-d-sub .exp-d-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.main-can-profile .work-exp .edu-detail .exp-d .exp-d-sub .exp-t {
  font-weight: bold;
}

.main-can-profile .work-exp .edu-detail .exp-d .exp-sub-t {
  font-weight: 400;
  color: rgb(140, 133, 148);
  font-size: 14px;
  line-height: 16px;
}

.main-can-profile .work-exp .edu-detail .exp-d .exp-b {
  font-weight: bold;
}

.main-can-profile .work-exp .edu-detail .exp-d .exp-opt span {
  flex-shrink: 0;
  background-color: rgb(244, 242, 246);
  border: 1px solid rgb(232, 231, 234);
  color: rgb(130, 116, 133);
  border-radius: 6px;
  padding: 6px 8px;
  font-weight: 400;
  display: flex;
  align-items: center;
  font-size: 10px;
}

/* add resume */
.main-can-profile .work-exp .exp-body .can-resume {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  flex-direction: column;
}

.modal-body .res-btn {
  border-radius: 4px;
  border: 1px solid black;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: black;
  padding: 10px 0px;
  gap: 8px;
  margin-top: 15px;
  cursor: pointer;
}

.main-can-profile .work-exp .exp-body .can-resume .res-btn {
  border-radius: 4px;
  border: 1px solid black;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: black;
  padding: 10px 0px;
  gap: 8px;
  margin-top: 15px;
  cursor: pointer;
}

.main-can-profile .work-exp .exp-body .can-resume .res-btn.prev{
  justify-content: space-between !important;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
}


/* other options */
.main-can-profile .work-exp .exp-body .other-d {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
}

.main-can-profile .work-exp .exp-body .other-d .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main-can-profile .work-exp .exp-body .other-d .head .title {
  font-size: 16px;
  font-weight: bold;
}

.main-can-profile .work-exp .exp-body .other-d .loc-d {
  display: flex;
  flex-direction: row;
}

/* profile tab */
.p-tab {
  padding: 5px 10px;
}

.p-tab.active {
  background-color: var(--primary-color);
  color: var(--color-light);
}

/* job detail */
@media (max-width:991px) {
  .job-detail {
    padding: 0px !important;
  }

  .job-detail .info {
    flex-direction: column;
    gap: 10px;
  }
}

/* company css */
.company-tabs ul {
  padding-left: 0px !important;
}

.company-tabs ul li {
  border: 1px solid var(--primary-color);
  padding: 3px 15px;
  border-radius: 15px;
  cursor: pointer;
}

.company-tabs ul li.active {
  background-color: var(--primary-color);
}

.company-tabs ul li.active a {
  color: var(--color-light);
}

.toggle-card.active {
  background-color: var(--primary-color);
  color: white;
}


/* admin css */
.admin-navbar {
  height: 100vh !important;
}

.admin-navbar .navbar-brand {
  width: 80%;
}

@media (max-width:991px) {

  .admin-navbar {
    height: 100px !important;
  }

  .admin-navbar.collapse {
    height: 100vh !important;
  }

  .admin-navbar .navbar-brand {
    width: 100px;
  }

  .admin-navbar .avatar-parent-child {
    width: 50px;
    clip-path: circle();
  }
}

.scroll-hide::-webkit-scrollbar{
  width: 0;
}

.modal-body textarea{
  height: 100px !important;
}

/* ashtech css */
.text-success-2 {
  color: #198754 !important;
}
.btn-primary_two {
  color: #fff !important;
  background-color: #007bff;
  border-color: #000 !important;
  border: 0;
  transition: all .3s ease 0s;
  border-radius: 23px;
}
.btn-sm {
  width: auto;
  min-width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.pr-2 {
  padding-right: 10px !important;
}

.float-left {
  float: left !important;
}
@media only screen and (min-width: 650px) {
 
  .container-sm {
      max-width: 70% !important;
  }
  .container-vsm{
    max-width: 50% !important;
    margin-right: auto;
    margin-left: auto;
  }
}
.container, .container-lg, .container-md, .container-sm,.container-vsm, .container-xl {
  width: 98% !important;
  /* min-height: 100vh; */
  margin-right: auto;
    margin-left: auto;
}

.border-box {
  box-shadow: 0 0 3px 0 rgb(2 2 2 / 26%);
  -webkit-box-shadow: 0 0 3px 0 rgb(2 2 2 / 26%);
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.navbar-expand-lg .navbar-toggler {
  display: block !important;
}
.collapse:not(.show) {
  display: none !important;
  left:-200px;
}
.navbar-collapse .collapse{
  left:-200px;
  position: absolute;
}
.ser_seachjob{
  padding: 0px 0px 0px 13px;
  background-color:rgba(0, 0, 0, 0);
}
.div-search{
  justify-content: center;
}

.mr-2{
  margin-right:2px;
}
.mr-5{
  margin-right:5px;
}
.mr-10{
  margin-right: 10px;
}

.ml-2{
  margin-left:2px;
}
.ml-5{
  margin-left: 5px;
}
.ml-10{
  margin-left: 10px;
}

.pl-2{
  padding-left:2px;
}
.pl-10{
  padding-left: 10px;
}


.ml-auto{
  margin-left: auto;
}
.mr-auto{
  margin-right: auto;
}

 .dropdown_search {
  min-width: auto;
  width: 100%;
  right: 2px;
  margin: auto;
  top: 65px;
  display: none;
  position:absolute;
  padding:20px 20px;

  background-color:rgb(40 103 178 / 45%);
}
.dropdown_search input{
  height: 30px;
}
.dropdown_search .btn-primary{
  background-color: #2a68b3 !important;
  padding:0px 0px;
}
.dropdown_search .expyear-col{
  /* height: 30px; */
  width: 60%;
}
.search-btn .fa{
  font-size: 1.5rem;
}
.search-icon{
  display: none;
}
.dropdown_search .btn{
  float: right;
}
@media (max-width:991px) {
  .w-100-mob{
    max-width: 50%;
    display: flex;
    margin-top: auto;
    justify-content: center; /* Aligns horizontally */
    align-items: center;     /* Aligns vertically */
  }
  .h-100-mob{
    min-height: 50vh !important;
    max-height: 50vh;
  }
  .search-icon {
    display: block;
  }
}
.card-title{
  float: left;
}
.float-right{
  float:right
}
.bg-primary{
  background-color: #179ab8!important;
  
}
.white{
  color:#fff !important
}
.modal-dialog{
  border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
}
.resume img{
  height: 250px;
  width: auto;
}
.editable::after {
  content: '✏️'; /* This is the symbol you want to add */
  margin-left: 5px; /* Adds some space between the box and the symbol */
  color: green; /* Optional: Color of the symbol */
  font-size: 15px; /* Optional: Size of the symbol */
  font-weight: bolder;
}

@media (max-width:700px) {
  .sc-iGgWBj::after{
    display: none;
  }
}
@media (max-width:700px) {
 .container{
  width: 100% !important;
  padding-right: var(--x-gutter-x, 1rem) !important;
  padding-left: var(--x-gutter-x, 1rem) !important;
  margin-right: auto !important;
  margin-left: auto !important;
 }
}
@media (max-width: 476px) {
  .col-sm-6 {
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 50% !important;
  }
}


.full-screen-header {
  /* height: 100vh; */
  /* background: rgb(40,103,178); */
  /* background: linear-gradient(180deg, rgba(40,103,178,1) 0%, rgba(219,235,255,1) 81%); */
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.bgimage_main{
  background-image: url("c_assets/images/newbg.jpg");
  background-repeat: repeat;
  
}
.btimage_world{
  background-image: url("c_assets/images/newbgmap.png");
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  /* opacity: 0.5; */
  /* background-repeat: repeat; */
  z-index: 2;
}
.btimage_world .row{
  z-index: 2;
}
.btimage_world222::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Change color and opacity as needed */
  z-index: 1;
  opacity: 0.5;
  
}
.opacity-1{
  opacity: 1;
}
.bg-gray-trans1{
  background-color:#8080802e;
}
/* .btimage_world a{
color:#fff;
} */


.pb-50{
padding-bottom: 50px;
}
.pt-50{
  padding-top: 50px;
}

.pb-100{
  padding-bottom: 100px;
}
.pt-100{
  padding-top: 100px;
}

.mt-auto{
  margin:auto;
}
/* 
.slider-nav-style-1 .swiper-buttons .swiper-button-next, .slider-nav-style-1 .swiper-buttons .swiper-button-prev {
  background-color: #ec802e;
} */


.swiper-button-next, .swiper-button-prev {
  position: absolute;
  top: 50%;
  /* width: calc(var(--swiper-navigation-size)-10 / 22* 2);*/
  height: var(--swiper-navigation-size); 
  width: 0px;
  height: 0px;
  margin-top: calc(-1* var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c51262;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  left: 20px;
  right: auto;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  right: 20px;
  left: auto;
}
.swiper-container{
  padding-left: 25px;
  padding-right: 25px;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 25px;
  font-weight: 900;
  padding:10px 10px;
  background-color: #0069b27d;
  border-radius: 50%;
  margin-top: auto;
}
.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 25px;
  font-weight: 900;
  padding:10px 10px;
  background-color: #0069b27d;
  border-radius: 50%;
  margin-top: auto;
}
.img-thumb {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}
.h-100{
  min-height: 100vh;
}
.fitcontent{
  min-width: fit-content !important;
}
.btn-saved-job{
  background-color: #198754 !important;
  color:#fff !important;
}



