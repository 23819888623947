.resume_showcase .card {
    height: auto;
    width: 100%;
    overflow: hidden;
  }
  .resume_showcase .card img {
    height: 260px;
    width: auto;
    object-fit: cover;
  }
  .resume_showcase .text-section {
    text-align: center;
    margin-top: 10px;
  }
  @media (max-width: 576px) {
    .resume_showcase .card {
        height: 250px; 
    }
    .resume_showcase .card img {
        height: 100%; 
        width: auto; 
        max-width: 100%; 
        object-fit: contain; 
    }
    .resume_showcase .text-section{
        font-size: 10px;
    }

  }