/* .modal-header{
    background-color: #40d195;
} */
.modal-body .amountdiv{
    text-align: center;
    font-size: 40px;
    font-weight: 600;
}
.checkmark .fa-check-circle-o{
    margin-top: 20px;
    color: #40d195;
    font-size: 50px;
}
.checkmark .fa-circle-check{
    margin-top: 20px;
    color: #40d195;
    font-size: 50px;
}
.checkmark .fa-times{
    margin-top: 20px;
    color: #e71212;
    font-size: 50px;
}
.checkmark{
    text-align: center;
}
.left{
    text-align: left;
    margin-top: 5px;
}
.right{
    text-align: right;
    margin-top: 5px;
}


.invoice-header{
    margin-bottom: 20px;
    border-bottom: 2px dotted rgb(212, 210, 210);
}

.invoice-header-left{
    text-align: left;
}
.modal-header img{
    width:150px;
}

.invoice-header-right {
    text-align: right;
}
.success-icon{
    text-align: center;
}
.success-icon i{
    color: #4cd379;
}
.left{
    text-align: left;
}
.right{
    text-align: right;
}