
.top-ind .row .card{
    margin: auto;
    max-width: 160px;
    max-height: 80px;
}
.top-ind .row .card:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;   
}
.top-ind .row .card i{
    font-size: 25px;
    color: rgb(50, 130, 184);
    margin: auto;
    border-radius: 35px;
}
.top-ind .row .card-body h5{
    font-size: 16px;
    font-weight: 600;
}
@media (max-width: 576px) {
.top-ind .card {
    margin: 10px auto; 
}

.top-ind .card .col-md-2 {
    display: flex;
    justify-content: center; 
    margin-bottom: 5px;
}

.top-ind .card-title {
    text-align: center;
}
.top-ind .row .card i{
    padding-top: 10px;
}
}