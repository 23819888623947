.full-screen-div {
    height: 100vh; 
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
  }

  .full-screen-div img {
    max-width: 100%;
    max-height: 70%; 
  }

  .full-screen-div .button-container {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .full-screen-div .button-container .btn {
    margin-right: 10px;
  }
  .full-screen-div .btn{
      background: rgb(252,70,107);
          background: linear-gradient(90deg, rgba(252,70,107,1) 0%, rgba(63,94,251,1) 100%);
          border-radius: 25px;
          color: white;
  }
